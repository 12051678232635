.body {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eee;
}

.error-message {
  color: #FFFFFF;
}

.input-field {
  margin-bottom: 10px;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.loginheader {
  min-height: 100px;
  background-color: #FFF;
  padding: 20px;
  display: flex;
}

.btn-white {
  color: #FFFFFF;
  border-color: #FFFFFF !important;
}

.loginbg {
  /* 0, 0, 0, 0.6 */
  background: linear-gradient(rgba(255, 83, 94, 0.6), rgba(0, 0, 0, 0.6)), url("/signInBackDrop.png");
  background-size: cover;
  height: 100vh;
}
